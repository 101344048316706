import DOMPurify from "dompurify";

type ParsedSupportingContentItem = {
    title: string;
    id: string;
    score: string;
    content: string;
};

export function parseSupportingContentItem(item: any): ParsedSupportingContentItem {
    const title = item.title;
    const id = item.id;
    const score = item.score;
    const content = DOMPurify.sanitize(item.content);

    return {
        id,
        score,
        title,
        content
    };
}
